import { useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Snowfall } from 'react-snowfall';
import SwiperType from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Product } from 'api/generated-types';
import Sticker from 'components/parts/Sticker';
import { ECImage, ECLink, Icon } from 'components/ui';
import { useCountryContext } from 'context/CountryContextProvider';
import { ImageSetKeys, useImages } from 'context/ImagesProvider';
import { useTracking } from 'context/TrackingProvider';
import { useTranslations } from 'services/translations/TranslationsProvider';
import { snowImages } from 'utils/festiveUtils';
import formatPrice from 'utils/formatPrice';
import useViewport from 'utils/hooks/useViewport';
import { getProductMaxPrice, getProductMinPrice } from 'utils/products';

interface Props {
  bestsellers: Product[];
  selectedRange?: 'dogs' | 'cats';
}

const productRanges = ['dogs', 'cats'];

const BestsellersFestive = ({ bestsellers, selectedRange }: Props): JSX.Element => {
  const { locale } = useRouter();
  const hasDogProducts = bestsellers.filter((p) => p.universalKey.includes('DOG')).length > 0;
  const hasCatProducts = bestsellers.filter((p) => p.universalKey.includes('CAT')).length > 0;
  const hasMultipleRanges = hasDogProducts && hasCatProducts;

  const [productRange, setProductRange] = useState<'dogs' | 'cats'>(
    selectedRange || (hasDogProducts ? 'dogs' : 'cats'),
  );
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const [isBeginning, setIsBeginning] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const { isMobile } = useViewport();

  const { t } = useTranslations('content.bestsellers');
  const { img } = useImages(ImageSetKeys.ContentBestsellers);
  const { currencyCode } = useCountryContext();
  const [showArrows, setShowArrows] = useState<boolean>(false);

  const {
    events: { trackCtaClicked },
  } = useTracking();

  /**
   * Handle arrow showing logic. Since we're dealing with a tabbed carousel, we need to rerun logic on tab change
   */
  const dogProducts = useMemo(
    () => bestsellers.filter((p) => p.universalKey.includes('DOG')),
    [bestsellers],
  );
  const catProducts = useMemo(
    () => bestsellers.filter((p) => p.universalKey.includes('CAT')),
    [bestsellers],
  );

  const calculateShowArrows = useCallback(() => {
    if (isMobile) {
      return false;
    }
    const currentProducts = productRange === 'dogs' ? dogProducts : catProducts;
    return currentProducts.length > 3;
  }, [isMobile, productRange, dogProducts, catProducts]);

  useEffect(() => {
    const shouldShowArrows = calculateShowArrows();
    setShowArrows(shouldShowArrows);
    if (swiper) {
      swiper.update();
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }
  }, [calculateShowArrows, swiper]);

  // This is to avoid adding festival phsyical bundle to the virtual bundle to avoid showing compareAtPrice
  const catFestiveUkey = 'BUNDLE_CAT_AD_CHUNKS-FESTIVE-3FLAV-6X85G';

  if (!bestsellers) {
    return <></>;
  }

  return (
    <div className="w-full">
      <div className="container">
        {/* heading */}
        <h3
          className={classNames(
            'text-3xl md:text-3.5xl leading-none text-center text-purple-700 font-rodetta max-w-sm mx-auto',
            !hasMultipleRanges && 'mb-12',
          )}
        >
          {t('festiveRangeTitle')}
        </h3>
        {/*  buttons  */}
        {hasMultipleRanges && (
          <div className="mt-6 w-fit mx-auto p-2 bg-white rounded-full flex items-center gap-2">
            {productRanges.map((range: 'dogs' | 'cats') => (
              <button
                key={range}
                type="button"
                className={`flex-1 relative overflow-hidden px-6 py-3 bg-purple-950 rounded-full ${
                  productRange === range
                    ? 'bg-purple-700 border border-purple-700'
                    : 'bg-white border border-gray-75'
                }`}
                onClick={() => {
                  setProductRange(range);
                  trackCtaClicked({ ctaId: `bestsellers_toggle_${range}` });
                }}
              >
                <ECImage
                  img={img(`${range}Button`)}
                  className={`absolute bottom-0 h-10 hidden xs:block ${
                    range === 'cats' ? 'right-3 sm:right-6' : 'left-0'
                  }`}
                />
                <div
                  className={`${range === 'dogs' ? 'xs:pl-10 sm:pl-16' : 'xs:pr-10 sm:pr-16'} ${
                    productRange === range ? 'text-white' : 'text-purple-700'
                  } text-lg font-rooney font-bold capitalize`}
                >
                  {t(range)}
                </div>
              </button>
            ))}
          </div>
        )}
        {/* carousel of product cards */}

        <div className="relative">
          <Swiper
            onSwiper={(newSwiper) => {
              setSwiper(newSwiper);
              setIsBeginning(newSwiper.isBeginning);
              setIsEnd(newSwiper.isEnd);
            }}
            onRealIndexChange={(s) => {
              setIsBeginning(s.isBeginning);
              setIsEnd(s.isEnd);
            }}
            slidesPerView={1.1}
            spaceBetween={16}
            pagination={{ clickable: true }}
            style={{
              marginBottom: -15,
              paddingBottom: 15,
              padding: 15,
              marginTop: 32,
              overflow: 'hidden',
            }}
            breakpoints={{
              350: {
                slidesPerView: 1.1,
                spaceBetween: 16,
              },
              640: {
                slidesPerView: 2.1,
                spaceBetween: 16,
              },
              768: {
                slidesPerView: 2.1,
                spaceBetween: 32,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 32,
              },
            }}
          >
            {bestsellers
              .filter((product) =>
                product.universalKey.includes(productRange.toUpperCase().replace('S', '')),
              )
              .map((product) => (
                <SwiperSlide
                  style={{
                    height: 'auto',
                    alignSelf: 'stretch',
                    userSelect: 'none',
                  }}
                  key={product.content.universalKey}
                >
                  <ECLink
                    href={`/products/${product.content.handle}/`}
                    className="flex flex-col h-full bg-white text-left rounded-2xl cursor-pointer shadow hover:shadow-lg
                    transform will-change-transform transition hover:-translate-y-[0.125rem]"
                  >
                    {/* cyan box */}
                    <div className="bg-cyan-400 rounded-t-2xl relative overflow-visible">
                      <Snowfall
                        color="#fff"
                        snowflakeCount={20}
                        radius={[8, 16.0]}
                        speed={[0.5, 1]}
                        wind={[-0.5, 0.5]}
                        style={{ zIndex: 3 }}
                        images={snowImages}
                      />
                      <div className="absolute inset-0 bg-gradient-radial from-[#C4ECEC] to-cyan-400 overflow-hidden rounded z-2 rounded-tr-2xl" />
                      <Image
                        src="/images/festive/snowcorner.svg"
                        alt="snow"
                        className="absolute w-[54px] left-0 top-0 -translate-x-[11%] -translate-y-[17%] pointer-events-none"
                        width={54}
                        height={48}
                      />
                      <div className="absolute top-3 right-3 flex flex-row-reverse flex-gap-x-2 flex-gap-y-1 mb-2 flex-wrap">
                        {/* ages sticker */}
                        <Sticker
                          sticker={{
                            displayText:
                              product.masterData.ages.length === 1
                                ? product.masterData.ages[0].translations?.find(
                                    (translation) => translation.languageCode === locale,
                                  )?.translation ?? product.masterData.ages[0].key
                                : t('allAges'),
                            backgroundHexColor: '#ecf8f8',
                            fontHexColor: '#007377',
                            name: 'ageLabel',
                            // Not needed, but is required for type
                            bannerActiveForCountries: [],
                          }}
                        />
                      </div>
                      {/* featured image */}

                      <div className="px-4 pb-6 pt-12 z-10">
                        {product.content.featuredImage && (
                          <div
                            className={classNames(
                              'flex  items-center justify-center cursor-pointer relative z-10 transition',
                            )}
                          >
                            <ECImage
                              img={product.content.featuredImage}
                              srcOptions={{ w: 720, q: 90 }}
                              className="w-full aspect-[1.25] object-contain max-h-[180px]"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {/* white text box */}
                    <div className="flex flex-col">
                      <div className="py-6 px-4">
                        <div
                          className={classNames(
                            'font-rooney font-bold text-lg md:text-xl leading-tight mb-1 text-gray-500',
                          )}
                        >
                          {product.content.title}
                        </div>
                        <div className="font-rooney font-medium text-base text-gray-450">
                          {getProductMinPrice(product)?.amount ===
                          getProductMaxPrice(product)?.amount ? (
                            <>
                              {getProductMaxPrice(product)?.formattedPrice}
                              {product.variants.some(
                                (variant) =>
                                  variant.content.isBundle &&
                                  product.universalKey !== catFestiveUkey,
                              ) && (
                                <span className="line-through text-gray-200 ml-1 font-medium">
                                  {formatPrice(
                                    getProductMaxPrice(product)?.compareAt,
                                    currencyCode,
                                    locale,
                                  )}
                                </span>
                              )}
                            </>
                          ) : (
                            `${t('from')} ${getProductMinPrice(product).formattedPrice}`
                          )}
                        </div>
                      </div>
                    </div>
                  </ECLink>
                </SwiperSlide>
              ))}
          </Swiper>
          {/* navigation buttons for larger screens */}
          {showArrows && (
            <>
              <button
                type="button"
                onClick={() => swiper?.slidePrev(200)}
                className={classNames(
                  `maxMd:hidden absolute top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 bg-purple-700 z-10 rounded-full transition-all
              hover:bg-purple-600 active:bg-purple-600 focus:bg-purple-600`,
                  isBeginning ? 'opacity-0' : 'opacity-100',
                )}
                hidden={isMobile}
              >
                <Icon
                  name="chevron-left"
                  className="w-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white"
                />
              </button>
              <button
                type="button"
                onClick={() => swiper?.slideNext(200)}
                className={classNames(
                  `maxMd:hidden absolute top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2 w-12 h-12 bg-purple-700 z-10 rounded-full transition-all
              hover:bg-purple-600 active:bg-purple-600 focus:bg-purple-600`,
                  isEnd ? 'opacity-0' : 'opacity-100',
                )}
                hidden={isMobile}
              >
                <Icon
                  name="chevron-right"
                  className="w-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white"
                />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BestsellersFestive;
