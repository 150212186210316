/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';

import classNames from 'classnames';
import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useRouter } from 'next/router';

import { Section } from 'components/ui';
import { useTranslations } from 'services/translations/TranslationsProvider';

import styles from './BrandMessage.module.css';

gsap.registerPlugin(ScrollTrigger);

const BrandMessage = (): JSX.Element => {
  const { t, tReplaceStrong } = useTranslations('content.brandMessage');
  const { locale } = useRouter();

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to('.titleLetters', {
        scrollTrigger: {
          trigger: '#title-group',
          scrub: 1,
          start: 'top 80%',
          end: 'bottom 80%',
        },
        duration: 1,
        stagger: 1,
        '--highlight-offset': '100%',
      });

      gsap.fromTo(
        '.nastiesLetters',
        { color: '#FFFFFF', textDecorationLine: 'line-through' },
        {
          scrollTrigger: {
            trigger: '#nasties-group',
            scrub: 1,
            start: 'top 80%',
            end: 'bottom 80%',
          },
          duration: 1,
          stagger: 1,
          color: '#fedd00', // yellow 600
          textDecorationLine: 'line-through',
        },
      );
    });

    return () => {
      ctx.revert();
    };
  }, []);

  const nastiesList = t('brandMessageNastiesList')
    .split(',')
    .map((nasty) => nasty.trim());

  return (
    <Section color="purple-700" id="brand-message">
      <div className="container container--lg">
        <h3
          id="title-group"
          className={classNames(
            'font-rodetta leading-none mb-16 break-words',
            locale === 'nl' ? 'text-3.5xl md:text-[4rem]' : 'text-4xl md:text-[4rem]',
          )}
        >
          <span className="text-white [&>strong]:text-yellow-600 [&>strong]:font-normal">
            {tReplaceStrong('brandMessageTitleLine1')[0]}
            {tReplaceStrong('brandMessageTitleLine1')[1]}
            {tReplaceStrong('brandMessageTitleLine1')[2]
              ?.split('')
              .map((letter, index) => (
                <span
                  key={`titleLetters-${index}`}
                  className={classNames('titleLetters', styles.titleLetters)}
                >
                  {letter}
                </span>
              ))}
          </span>
          <br />
          <span className="text-cyan-400">{t('brandMessageTitleLine2')}</span>
        </h3>

        <div className="text-white text-xl md:text-2xl font-rooney font-bold mb-6">
          {t('brandMessageSubtitle')}
        </div>
        <ul
          id="nasties-group"
          className="flex flex-col gap-1 text-lg md:text-xl font-rooney font-medium line-through"
        >
          {nastiesList.map((nasty) => (
            <li key={nasty}>
              {nasty.split('').map((letter, index) => (
                <span key={`nastiesLetters-${index}`} className="nastiesLetters">
                  {letter}
                </span>
              ))}
            </li>
          ))}
        </ul>
      </div>
    </Section>
  );
};

export default BrandMessage;
