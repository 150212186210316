import { useEffect } from 'react';

import classNames from 'classnames';
import { gql } from 'graphql-request';
import { GetStaticPaths, GetStaticProps } from 'next';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Snowfall from 'react-snowfall';

import { bffRequest } from 'api/client/bff-request';
import productFragment from 'api/client/fragments/collectionProduct';
import { Product } from 'api/generated-types';
import Benefits from 'components/content/Benefits';
import BestsellersFestive from 'components/content/BestsellersFestive';
import BrandMessage from 'components/content/BrandMessage';
import Charity from 'components/content/Charity';
import Footnote2 from 'components/content/Footnote2';
import InfluencerVideo from 'components/content/InfluencerVideo';
import ProductQuizActivation2 from 'components/content/ProductQuizActivation2';
import Testimonials from 'components/content/Testimonials';
import Newsletter2 from 'components/parts/Newsletter2';
import TrustBox from 'components/parts/TrustBox';
import { Button, ECImage, Screen, Section, SectionItem } from 'components/ui';
import { ImageSetKeys, getImageSets, useImages } from 'context/ImagesProvider';
import { useIntercom } from 'context/IntercomProvider';
import { useTracking } from 'context/TrackingProvider';
import Layout from 'layouts/Layout';
import { Testimonial } from 'services/contentful/generated-types';
import serverSideSeo from 'services/seo/serverSideSeo';
import serverSideTranslations from 'services/translations/serverSideTranslations';
import { useTranslations } from 'services/translations/TranslationsProvider';
import { getShippingCountryCodesDuringBuild } from 'utils/country/getShippingCountryCodes';
import { snowImages } from 'utils/festiveUtils';
import { localesWithoutDefault } from 'utils/localeHelpers';
import createNextPage from 'utils/pages/createNextPage';
import { fetchTestimonialsByKey } from 'utils/testimonialUtils';

import styles from './home.module.css';

interface Props {
  bestsellersData: Product[];
  testimonials: Testimonial[];
}

// const bestsellersUniversalKeys = [
//   'DOG_AD_KIBBLE_SALMON40',
//   'DOG_AD_PATE_CHICKEN52-TURKEY8',
//   'DOG_JR_KIBBLE_DUCK10-CHICKEN40',

//   'CAT_AD_KIBBLE_CHICKEN50',
//   'CAT_AD_CHUNKS_CHICKEN46',
//   'CAT_JR_KIBBLE_DUCK10-CHICKEN40',
// ];

export default createNextPage<Props>(
  ({ bestsellersData, testimonials }: Props) => {
    const { t, tReplaceStrong } = useTranslations('page.home.2024');
    const { img } = useImages(ImageSetKeys.PageHome2024);
    const { showIntercom } = useIntercom();

    const { locale } = useRouter();

    const {
      events: { trackCtaClicked },
    } = useTracking();

    useEffect(() => {
      if (window.location.hash.indexOf('intercom') > 0) {
        showIntercom();
      }
    });

    return (
      <div className="overflow-hidden">
        <h1 className="visually-hidden">{t('hiddenPageTitle')}</h1>

        <div className="bg-cyan-400 relative bg-gradient-to-t from-white/75 via-cyan-400 to-cyan-400 overflow-hidden">
          <div className="container">
            <div className="grid grid-cols-12">
              <div className="col-span-12 lg:col-span-6 xl:col-span-5 flex flex-grow items-center">
                <div className="maxLg:max-w-copy text-center pt-12 pb-6 lg:py-20 lg:text-left mx-auto">
                  <h2
                    className={classNames(
                      'inline-block leading-none text-purple-700 font-rodetta mb-6 ',
                      locale === 'en' || locale === 'fr'
                        ? 'text-3.5xl lg:text-4xl xl:text-4xl'
                        : 'text-3.5xl lg:text-4xl',
                      '[&>strong]:text-purple-700 [&>strong]:font-normal',
                    )}
                  >
                    {tReplaceStrong('festiveHeroTitle')}
                  </h2>
                  <div className="mb-8 text-white text-xl font-rooney font-medium lg:max-w-[80%]">
                    <p>{t('festiveHeroSubtitle')}</p>
                  </div>
                  {/* hero buttons */}
                  <div className="flex flex-wrap gap-4 justify-center lg:justify-start">
                    <span className="relative flex grow sm:grow-0">
                      {/* shop dogs */}
                      <Image
                        src="/images/festive/snowcorner.svg"
                        alt="snow"
                        className="absolute w-[40px] left-0 top-0 z-10 -translate-x-[11%] -translate-y-[17%] pointer-events-none"
                        width={40}
                        height={36}
                      />
                      <Link href="/collections/dogs/" passHref legacyBehavior>
                        <Button
                          className="grow sm:grow-0 !px-6"
                          color="purple"
                          size="large"
                          onClick={() => trackCtaClicked({ ctaId: 'hero_shop_dogs' })}
                        >
                          {t('heroCtaDogs')}
                        </Button>
                      </Link>
                    </span>
                    {/* shop cats */}
                    <span className="relative flex grow sm:grow-0">
                      <Image
                        src="/images/festive/snowcorner.svg"
                        alt="snow"
                        className="absolute w-[40px] right-0 top-0 z-10 translate-x-[11%] -translate-y-[17%] -scale-x-100 pointer-events-none"
                        width={40}
                        height={36}
                      />
                      <Link href="/collections/cats/" passHref legacyBehavior>
                        <Button
                          className="grow sm:grow-0 !px-6"
                          color="purple"
                          size="large"
                          onClick={() => trackCtaClicked({ ctaId: 'hero_shop_cats' })}
                        >
                          {t('heroCtaCats')}
                        </Button>
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-6 xl:col-span-7 flex items-end justify-center">
                <div className={styles.treeWrapper}>
                  <ECImage
                    img={img('heroFestiveTree')}
                    srcOptions={{ w: 1240, q: 90 }}
                    loading="eager"
                    className={classNames(
                      'w-full maxLg:max-w-lg maxLg:mx-auto relative z-10',
                      styles.tree,
                    )}
                  />
                  <ECImage
                    img={img('heroFestiveRoxieHead')}
                    loading="eager"
                    className={classNames(styles.treeRoxieHead)}
                  />
                  <ECImage
                    img={img('heroFestiveRoxieArm')}
                    loading="eager"
                    className={classNames(styles.treeRoxieArm)}
                  />
                  <ECImage
                    img={img('heroFestiveEdgardHead')}
                    loading="eager"
                    className={classNames(styles.treeEdgardHead)}
                  />

                  <div>
                    <Image
                      src="/images/festive/bulb.png"
                      alt="bulb"
                      className={classNames('top-[75%] left-[16%]', styles.bulb)}
                      width={40}
                      height={40}
                    />
                    <Image
                      src="/images/festive/bulb.png"
                      alt="bulb"
                      className={classNames('top-[75%] left-[80%]', styles.bulb)}
                      width={40}
                      height={40}
                    />
                    <Image
                      src="/images/festive/bulb.png"
                      alt="bulb"
                      className={classNames('top-[78%] left-[38%]', styles.bulb)}
                      width={40}
                      height={40}
                    />
                    <Image
                      src="/images/festive/bulb.png"
                      alt="bulb"
                      className={classNames('top-[85%] left-[64%]', styles.bulb)}
                      width={40}
                      height={40}
                    />
                    <Image
                      src="/images/festive/bulb.png"
                      alt="bulb"
                      className={classNames('top-[46%] left-[37%]', styles.bulb)}
                      width={40}
                      height={40}
                    />
                    <Image
                      src="/images/festive/bulb.png"
                      alt="bulb"
                      className={classNames('top-[52%] left-[57%]', styles.bulb)}
                      width={40}
                      height={40}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Image
            src="/images/festive/snowLandscape.svg"
            alt="snow"
            className="absolute z-30 w-full top-[100%] translate-y-[-10%] pointer-events-none min-h-[100px] object-cover"
            width={40}
            height={36}
          />
          <>
            <Snowfall
              color="#fff"
              snowflakeCount={20}
              radius={[8, 16.0]}
              speed={[0.5, 1]}
              wind={[-0.5, 0.5]}
              style={{ zIndex: 1 }}
              images={snowImages}
            />
          </>
        </div>

        <Section color="white" size="small">
          <Screen>
            <TrustBox template="micro-combo" className="z-30" />
          </Screen>
          <Screen md>
            <TrustBox template="micro-star" className="z-30" />
          </Screen>
        </Section>

        <Section color="brown-100" className="!pt-12">
          <SectionItem>
            <BestsellersFestive bestsellers={bestsellersData} />
          </SectionItem>
          <SectionItem>
            <Benefits />
          </SectionItem>
          <InfluencerVideo />
        </Section>

        <ProductQuizActivation2 ctaId="home_product_quiz_landing" />

        <Section color="brown-100">
          <Testimonials testimonials={testimonials} />
        </Section>

        <BrandMessage />

        <Section color="brown-100" noBottomSpacing>
          <SectionItem>
            <Charity />
          </SectionItem>
          <SectionItem>
            <Newsletter2 />
          </SectionItem>
        </Section>

        <Footnote2 />
      </div>
    );
  },
  {
    Layout,
  },
);

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  const countryCodes = await getShippingCountryCodesDuringBuild();

  /**
   * WARNING:
   * BE is filtered out because of the different template for BE
   */
  return {
    paths: countryCodes
      .filter((c) => c !== 'BE')
      .flatMap((countryCode) =>
        localesWithoutDefault(locales).map((locale) => ({
          params: {
            countryCodeWithPrefix: `country-${countryCode}`,
          },
          locale,
        })),
      ),
    fallback: 'blocking',
  };
};

const bestsellersQuery = gql`
  ${productFragment}
  query getBestsellers($universalKeys: [String!], $locale: String!, $countryCode: String!) {
    products(universalKeys: $universalKeys, locale: $locale, countryCode: $countryCode) {
      ...collectionProductFields
    }
  }
`;

export const getStaticProps: GetStaticProps<unknown, { countryCodeWithPrefix: string }> = async (
  ctx,
) => {
  const { locale, params } = ctx;
  if (locale === 'default') return { props: {} };
  const { countryCodeWithPrefix } = params;
  const countryCode = countryCodeWithPrefix.split('-')[1];

  const bestsellerFestiveUniversalKeys = [
    'BUNDLE_DOG_AD_MIX-FEST-1',
    'DOG_JR-AD-SR_BISCUIT_DUCK10-TURKEY16-FESTIVE',
    'DOG_AD_PATE_TURKEY60-FESTIVE',
    'DOG_AD_PATE_TURKEY55-FESTIVE',
    countryCode === 'IT' ? null : 'BUNDLE_CAT_AD_CHUNKS-FESTIVE-3FLAV-6X85G',
    'CAT_AD_PATE_DUCK10-FESTIVE',
    countryCode === 'IT' ? null : 'CAT_AD_CHUNKS_VENISON7-FESTIVE',
  ].filter(Boolean);

  const [resources, images, seo, testimonials] = await Promise.all([
    serverSideTranslations(
      locale,
      [
        'common',
        'products',
        'form',
        'alerts.newsletter',
        'page.home',
        'page.home.2024',
        'content.newsletter',
        'content.charityDonation',
        'content.footnote',
        'content.benefits',
        'content.bestsellers',
        'content.testimonials',
        'content.whyFresh',
        'content.freshVersusTraditional',
        'content.productQuizActivation',
        'content.charity',
        'content.brandMessage',
        'content.footnote2',
        'content.influencer',
      ],
      ctx,
    ),
    getImageSets(
      {
        locale,
        keys: [
          ImageSetKeys.PageHome2024,
          ImageSetKeys.ContentWhyFresh,
          ImageSetKeys.ContentFreshVersusTraditional,
          ImageSetKeys.ContentProductQuizActivation,
          ImageSetKeys.ContentNewsletter,
          ImageSetKeys.ContentBenefits,
          ImageSetKeys.ContentBestsellers,
          ImageSetKeys.ContentCharity,
          ImageSetKeys.ContentInfluencer,
        ],
      },
      ctx,
    ),
    serverSideSeo(locale, 'page.home', ctx),
    fetchTestimonialsByKey('testimonials.dogAndCat', locale),
  ]);

  const bestsellersData = await bffRequest<{ products: Product[] }>({
    query: bestsellersQuery,
    variables: {
      locale,
      countryCode,
      universalKeys: bestsellerFestiveUniversalKeys,
    },
    ctx,
  }).then((res) => {
    res.products?.sort(
      (a, b) =>
        bestsellerFestiveUniversalKeys.findIndex((p) => p === a.universalKey) -
        bestsellerFestiveUniversalKeys.findIndex((p) => p === b.universalKey),
    );

    return res.products;
  });

  return !resources
    ? { notFound: true }
    : {
        props: {
          resources,
          seo,
          images,
          countryCode,
          bestsellersData,
          testimonials,
        },
        revalidate: parseInt(process.env.NEXT_REVALIDATE_SECONDS, 10),
      };
};
